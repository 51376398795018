Vue.directive('vue-switch', {
    twoWay: true,
    priority: 1000,

    params: ['onText', 'offText'],

    bind: function () {

        var self = this
        
        $(this.el)
            .bootstrapSwitch({
                onText : this.params.onText,
                offText : this.params.offText,
            })
            .on('switchChange.bootstrapSwitch', function(event, state) {
                // $(self.el).val(state ? 1 : 0);
                console.log('changing value');
                self.set(state);
            });
    },
    update: function (value) {
        $(this.el).bootstrapSwitch('state', value, true);
    },
    unbind: function () {
        $(this.el).off().bootstrapSwitch('destroy')
    }
})