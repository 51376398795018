Vue.directive('vue-datetime-picker', {
    twoWay: true,
    priority: 1000,

    params: ['dt-format'],

    bind: function () {

        var self = this

        $(this.el)
            .datetimepicker({
                'format' : this.params.dtFormat
            })
            .on('change', function () {
                self.set($(self.el).val())
            })
    },
    update: function (value) {
        $(this.el).val(value).trigger('change')
    },
    unbind: function () {
        $(this.el).off().datetimepicker('destroy')
    }
})