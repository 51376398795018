$('#carpenter-sidebar-toggle').click(function() {
    toggleSidebar();
})


function toggleSidebar() {
    var $carpenterWrapper = $('#carpenter-wrapper');
    var $sidebarDiv = $('#sidebar-division');

    $sidebarDiv.toggleClass('toggled');
    $carpenterWrapper.toggleClass('toggled');
}

$(document).on('dblclick', function(el) {
    //console.log('clicked', el, el.target.nodeName);
    if (el.target.nodeName == 'DIV') {
        $('#jsonDD').toggle();
    }
    if (el.target.nodeName == 'PRE' && el.target.id == 'jsonDD') {
        $('#jsonDD').css('height', 'auto');
    }
});

function updateChart(data) {

    data = eval(data);
    
    var container = document.getElementById('timeline');
    var chart = new google.visualization.Timeline(container);
    var dataTable = new google.visualization.DataTable();
    var d = new Date();

    dataTable.addColumn({ type: 'string', id: 'User' });
    dataTable.addColumn({ type: 'string', id: 'Task' });
    dataTable.addColumn({ type: 'datetime', id: 'Start' });
    dataTable.addColumn({ type: 'datetime', id: 'End' });

    if (data instanceof Array) {
        dataTable.addRows(data);
    }

    var options = {
        hAxis: {
            minValue: new Date(d.getFullYear(), d.getMonth()+1, d.getDate(), 9, 0, 0),
            maxValue: new Date(d.getFullYear(), d.getMonth()+1, d.getDate(), 18, 0, 0)
        }
    };
    chart.draw(dataTable, options);
}

function checkTimeLineUpdate() {
    $.get('/api/timelog/retrieve').success(function (data) {
        updateChart(data);
    });
}

// $('.slimScrollDiv').slimScroll({
//     height: '250px'
// });